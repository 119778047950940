//address state swapper

import { mobileMenu } from 'frontend/mobile_menu';
import { cookieConsent } from "frontend/cookie_consent";
import { customerReviews } from 'frontend/customer_reviews';
import { quotes } from 'frontend/quotes';
import { static_pages } from 'frontend/static_pages';
import { reviews } from 'frontend/reviews';
import { products } from 'frontend/products';
import '../flash_messages';


window.addEventListener('DOMContentLoaded', (event) => {

  window.addEventListener('load', function() {
	if (document.querySelector('.banner-container')) {
		document.querySelector('.banner-container').classList.add('loaded');
	}
  });


	let items = document.querySelectorAll(".menuul li");
	for(let i = 0; i < items.length; i++){
		items[i].insertAdjacentHTML("afterbegin", '<span class="icon-arrow iconic-angle-double-right"></span>')
	}

	if(document.getElementById("mendiv1") !== null){
		document.getElementById("mendiv1").addEventListener("click", function(e){
			let slidable = document.getElementById("menu1")
			if( slidable.clientHeight === 0){
					slidable.style = "max-height: 225px; border-width: 1px;"
			}else{
				slidable.style = "max-height: 0px;"
			}
		}, false);
	}

	if(document.getElementById("mendiv3") !== null){
		document.getElementById("mendiv3").addEventListener("click", function(e){
			let slidable = document.getElementById("menu3")
			if( slidable.clientHeight === 0){
					slidable.style = "max-height: 675px; border-width: 1px;"
			}else{
				slidable.style = "max-height: 0px;"
			}
		}, false);
	}

	if(document.getElementById("mendiv4") !== null){
		document.getElementById("mendiv4").addEventListener("click", function(e){
			let slidable = document.getElementById("menu4")
			if( slidable.clientHeight === 0){
					slidable.style = "max-height: 2300px; border-width: 1px;"
			}else{
				slidable.style = "max-height: 0px;"
			}
		}, false);
	}

  scrollFunction();
  window.onscroll = function() {scrollFunction()};

  function scrollFunction() {
    const activeElement = document.activeElement;
    if (activeElement && (activeElement.tagName === 'INPUT' || activeElement.tagName === 'TEXTAREA')) {
      activeElement.blur();
    }

    var sideNavBtn = document.querySelector('.side-nav-btn')
    if (document.documentElement.scrollTop > 50) {
      document.getElementById("sub-navbar").style.position = "absolute";
      document.getElementById("sub-navbar").style.top = "-100px";
      sideNavBtn.style.display = 'block'
    } else {
      document.getElementById("sub-navbar").style.display = "block";
      document.getElementById("sub-navbar").style.position = "static";
      document.getElementById("sub-navbar").style.top = "0";
      sideNavBtn.style.display = 'none'
    }
  }


	/////////////////////////////////////


		//initialize loader
		var loader = document.createElement("div");
		loader.className = "loader hide";
		document.body.appendChild(loader);

		document.addEventListener("ajax:send", function(){
			loader.className = "loader";
		}, false);

		document.addEventListener("ajax:complete", function(){
			loader.className = "loader hide";
		}, false);

		document.addEventListener("ajax:error", function(){
			loader.className = "loader hide";
		}, false);
		//end init loader

		//loading lazy images
		var lazyImages = [].slice.call(document.querySelectorAll("img.lazy"));
		if ("IntersectionObserver" in window) {
			var lazyImageObserver = new IntersectionObserver(function(entries, observer) {
				entries.forEach(function(entry) {
					if (entry.isIntersecting) {
						var lazyImage = entry.target;
						lazyImage.src = lazyImage.dataset.src;
						if ("srcset" in lazyImage.dataset) {
							lazyImage.srcset = lazyImage.dataset.srcset;
						}

						lazyImage.classList.remove("lazy");
						lazyImageObserver.unobserve(lazyImage);
					}
				});
			});

			lazyImages.forEach(function(lazyImage) {
				lazyImageObserver.observe(lazyImage);
			});
		} else {
			//if IntersectionObserver not found
			lazyImages.forEach(function(lazyImage){
				lazyImage.src = lazyImage.dataset.src;
			});
		}


		let mod = document.getElementsByClassName("mod_1");
		for(let i = 0; i < mod.length; i++){

			mod[i].addEventListener("click", function(e){
				e.preventDefault();
				e.stopPropagation();

				var slidable = document.querySelector(".mod_1_open");
				if( slidable.clientHeight === 0){
						slidable.style = "max-height: 225px;"
				}else{
					slidable.style = "max-height: 0px;"
				}

			}, false);

		}





		//optimized youtube video loading
		function loadVideo(){
        this.removeEventListener("click", loadVideo);

        var iframe = document.createElement("iframe");
        iframe.src = this.dataset.src;
        iframe.setAttribute("frameborder", "0");
        iframe.setAttribute("allowfullscreen", "true");
        iframe.className = this.dataset.klass;
        if(this.dataset.id !== undefined && this.dataset.id != ""){
        	iframe.id = this.dataset.id;
        }
        if(this.dataset.setHeight == "true"){
            iframe.height = this.clientHeight;
        }
        this.innerHTML = "";
        this.appendChild(iframe);
    }

    var placeholders = document.getElementsByClassName("yt-placeholder");
    for(var i = 0; i < placeholders.length; i++){
        placeholders[i].addEventListener("click", loadVideo, false);
    }



		//initialize mobile menu controls
		mobileMenu.init();

		//load cookie consent
		cookieConsent.load();

		quotes.init();

		//looks for review search text field and initializes listeners if that element exists - only on review-index pages.
		if(document.getElementById("reviewSearchTF") !== null){
			reviews.init();
		}

		//if on customer review page init the form
		if(document.getElementById("new_customer_review")){
			customerReviews.init();
		}

		if(document.getElementById("product-detailed-view")){
			products.init();
		}


		static_pages.init();

		//loads ads FIRST
    var adsExist = td.adLoader.load();
		//load trustpilot
		td.utils.loadScript("//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js", (function(){}),document.head);



}, false);
